<template lang="html">
  <div class="container">
    <LogoComponent />
    <div class="col-md-6 offset-md-3 text-center mb-5 mt-3">
      <h1>Signeer jullie plaat</h1>
    </div>
    <div class="col-md-6 offset-md-3 text-center mb-3">
      <textarea
        class="form-control text-center"
        rows="5"
        placeholder="Weet je nog hoe we hebben gedanst op deze plaat in Spanje... -XXX-"
        v-model="message"
        @keydown="onKeyDown"
      ></textarea>
      <span class="mb-3" v-if="this.$store.getters.getMessage"
        >{{ max - this.$store.getters.getMessage.length }}/{{ max }}</span
      >
      <span class="mb-3" v-if="!this.$store.getters.getMessage"
        >{{ max }}/{{ max }}</span
      >
    </div>
    <div class="col-md-6 offset-md-3 text-center mb-3">
      <div class="d-grid">
        <button
          type="button"
          :disabled="disabledBtn"
          class="btn btn-primary btn-lg"
          @click="toSender()"
        >
          Verstuur jullie plaat
        </button>
        <button
          type="button"
          class="btn btn-outline-secondary btn-lg mt-3"
          @click="goBack()"
        >
          Vorige
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import LogoComponent from "@/components/LogoComponent.vue";

export default {
  name: "MessageView",
  components: {
    LogoComponent,
  },
  computed: {
    disabledBtn() {
      if (this.$store.getters.getMessage) {
        return false;
      } else {
        return true;
      }
    },
    message: {
      get() {
        return this.$store.getters.getMessage;
      },
      set(value) {
        this.$store.commit("setMessage", value);
      },
    },
  },
  data() {
    return {
      max: 100,
    };
  },
  methods: {
    toSender() {
      this.$router.push("/sender");
    },
    goBack() {
      this.$router.push("/receiver");
    },
    onKeyDown(evt) {
      if (this.$store.getters.getMessage) {
        if (this.$store.getters.getMessage.length >= this.max) {
          if (evt.keyCode >= 48 && evt.keyCode <= 90) {
            evt.preventDefault();
            return;
          }
        }
      }
    },
  },
};
</script>

<style lang="css" scoped></style>
