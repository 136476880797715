<template lang="html">
  <div class="container">
    <LogoComponent />
    <div class="col-md-6 offset-md-3 text-center mb-5 mt-3">
      <h1>Wie heeft deze plaat aangevraagd?</h1>
    </div>
    <div class="col-md-6 offset-md-3 mb-3">
      <input
        class="form-control form-control-lg text-center"
        type="textarea"
        placeholder="Jouw naam"
        v-model="senderName"
      />
    </div>
    <div class="col-md-6 offset-md-3 mb-3 text-center">
      <input
        class="form-control form-control-lg text-center"
        type="textarea"
        placeholder="Jouw e-mail adres"
        v-model="senderEmailaddress"
      />
      <span
        class="muted"
        v-if="!validateEmail && this.$store.getters.getSenderEmailaddress"
        ><small>Nog geen valide e-mail adres</small></span
      >
    </div>
    <div class="col-md-6 offset-md-3 text-center">
      <div class="d-grid">
        <button
          type="button"
          :disabled="disabledBtn || !validateEmail"
          class="btn btn-primary btn-lg mt-3"
          @click="saveAndToThankYou()"
        >
          Bevestig plaat
        </button>

        <button
          type="button"
          :disabled="disabledBtn"
          class="btn btn-outline-secondary btn-lg mt-3"
          @click="goBack()"
        >
          Vorige
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import LogoComponent from "@/components/LogoComponent.vue";

export default {
  name: "SenderView",
  components: {
    LogoComponent,
  },
  computed: {
    validateEmail() {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regex.test(this.$store.getters.getSenderEmailaddress);
    },
    disabledBtn() {
      if (
        this.$store.getters.getSenderName &&
        this.$store.getters.getSenderEmailaddress &&
        !this.$store.getters.getLoading
      ) {
        return false;
      } else {
        return true;
      }
    },
    senderName: {
      get() {
        return this.$store.getters.getSenderName;
      },
      set(value) {
        this.$store.commit("setSenderName", value);
      },
    },
    senderEmailaddress: {
      get() {
        return this.$store.getters.getSenderEmailaddress;
      },
      set(value) {
        this.$store.commit("setSenderEmailaddress", value);
      },
    },
  },
  methods: {
    saveAndToThankYou() {
      this.$store.dispatch("saveEntry");
      this.$router.push("/sender");
    },
    goBack() {
      this.$router.push("/message");
    },
  },
};
</script>

<style lang="css" scoped></style>
