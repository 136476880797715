<template lang="html">
  <!-- Button trigger modal -->
  <!-- <div class="d-grid">
    <button
      type="button"
      class="btn btn-secondary mb-3"
      data-bs-toggle="modal"
      data-bs-target="#SongModal"
    >
      Search song
      <i class="bi bi-spotify"></i>
    </button>
  </div> -->

  <!-- Modal -->
  <div
    class="modal fade"
    id="SongModal"
    tabindex="-1"
    aria-labelledby="SongModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-body">
          <div class="input-group mb-3">
            <input
              v-model="searchstring"
              type="text"
              class="form-control"
              placeholder="Zoek jullie heartbeat"
              v-on:keyup.enter="fetchSongs()"
            />
            <button
              class="btn btn-outline-secondary"
              type="button"
              @click="fetchSongs()"
              :disabled="searchstring.length < 3"
            >
              Zoek
              <i class="bi bi-spotify"></i>
            </button>
          </div>
          <!-- Show Tracks -->
          <ul class="list-group list-group-numbered">
            <li
              v-for="track in tracks"
              v-bind:key="track.uri"
              class="list-group-item d-flex justify-content-between align-items-start show-hand"
              @click="selectTrack(track)"
            >
              <div class="ms-2 me-auto">
                <div class="fw-bold">{{ track.name }}</div>
                Artist:
                {{
                  track.artists && track.artists.length > 0
                    ? track.artists[0].name
                    : "Unknown Artist"
                }}
              </div>
              <span class="badge bg-primary rounded-pill">
                {{ millisecondsToTime(track.duration_ms) }}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ModalComponent",
  data() {
    return {
      searchstring: "",
      tracks: [],
    };
  },
  created() {},
  methods: {
    selectTrack(track) {
      this.$store.commit("setTrack", track);
      this.$router.push("/preview");
    },
    fetchSongs() {
      axios
        .get("https://api.sendyourheartbeat.com/search.php", {
          params: {
            track: encodeURIComponent(this.searchstring),
          },
          // headers: {
          //   "api-key": "API-8fe9886aa07d0fd6fe2ed2138b650073b910f84a",
          // },
        })
        .then((response) => {
          // console.log(response.data.tracks.items);
          this.tracks = response.data.tracks.items;
        })
        .catch((err) => console.log(err));
    },
    millisecondsToTime(milli) {
      // var milliseconds = milli % 1000;
      var seconds = Math.floor((milli / 1000) % 60);
      var minutes = Math.floor((milli / (60 * 1000)) % 60);
      return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
    },
  },
};
</script>

<style lang="css" scoped>
.show-hand {
  cursor: pointer;
}
</style>
