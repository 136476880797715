<template lang="html">
  <div class="container">
    <LogoComponent />
    <div class="col-md-6 offset-md-3 text-center mb-5 mt-3">
      <h1>Wie ontvangt jouw plaatje?</h1>
    </div>
    <div class="col-md-6 offset-md-3 text-center mb-3">
      <input
        class="form-control form-control-lg text-center"
        type="text"
        placeholder="Naam"
        v-model="receiverName"
      />
    </div>
    <div class="col-md-6 offset-md-3 text-center mb-3">
      <input
        class="form-control form-control-lg text-center"
        type="text"
        placeholder="Straat en huisnummer"
        v-model="receiverStreet"
      />
    </div>
    <div class="col-md-6 offset-md-3 text-center mb-3">
      <input
        class="form-control form-control-lg text-center"
        type="text"
        placeholder="Postcode"
        v-model="receiverPostcode"
      />
    </div>
    <div class="col-md-6 offset-md-3 text-center mb-3">
      <input
        class="form-control form-control-lg text-center"
        type="text"
        placeholder="Plaats"
        v-model="receiverCity"
      />
    </div>
    <div class="col-md-6 offset-md-3 text-center">
      <div class="d-grid">
        <button
          type="button"
          :disabled="disabledBtn"
          class="btn btn-primary btn-lg mt-3"
          @click="toMessage()"
        >
          Send your heartbeat!
        </button>
        <button
          type="button"
          class="btn btn-outline-secondary btn-lg mt-3"
          @click="goBack()"
        >
          Vorige
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import LogoComponent from "@/components/LogoComponent.vue";

export default {
  name: "ReceiverView",
  components: {
    LogoComponent,
  },
  created() {
    this.$store.commit("pausePreviewTrack");
  },
  computed: {
    disabledBtn() {
      if (
        this.$store.getters.getReceiverName &&
        this.$store.getters.getReceiverStreet &&
        this.$store.getters.getReceiverPostcode &&
        this.$store.getters.getReceiverCity
      ) {
        return false;
      } else {
        return true;
      }
    },
    receiverName: {
      get() {
        return this.$store.getters.getReceiverName;
      },
      set(value) {
        this.$store.commit("setReceiverName", value);
      },
    },
    receiverStreet: {
      get() {
        return this.$store.getters.getReceiverStreet;
      },
      set(value) {
        this.$store.commit("setReceiverStreet", value);
      },
    },
    receiverPostcode: {
      get() {
        return this.$store.getters.getReceiverPostcode;
      },
      set(value) {
        this.$store.commit("setReceiverPostcode", value);
      },
    },
    receiverCity: {
      get() {
        return this.$store.getters.getReceiverCity;
      },
      set(value) {
        this.$store.commit("setReceiverCity", value);
      },
    },
    receiverCountry: {
      get() {
        return this.$store.getters.getReceiverCountry;
      },
      set(value) {
        this.$store.commit("setReceiverCountry", value);
      },
    },
  },
  methods: {
    toMessage() {
      this.$router.push("/message");
    },
    goBack() {
      this.$router.push("/preview");
    },
  },
};
</script>

<style lang="css" scoped></style>
