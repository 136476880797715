import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import TrackPreview from "../views/TrackPreview.vue";
import ReceiverView from "../views/ReceiverView.vue";
import SenderView from "../views/SenderView.vue";
import MessageView from "../views/MessageView.vue";
import ThankYou from "../views/ThankYou.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/preview",
    name: "preview",
    component: TrackPreview,
  },
  {
    path: "/receiver",
    name: "receiver",
    component: ReceiverView,
  },
  {
    path: "/message",
    name: "message",
    component: MessageView,
  },
  {
    path: "/sender",
    name: "sender",
    component: SenderView,
  },
  {
    path: "/thankyou",
    name: "thankyou",
    component: ThankYou,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Using the variables to satisfy ESLint, even if not directly utilized
    if (to || from || savedPosition) {
      // This block is intentionally left blank as the variables are not needed here
    }
    return { top: 0 }; // Scrolls to the top of the page on route change
  },
});

export default router;
