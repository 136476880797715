<template lang="html">
  <div class="container">
    <div class="row p-3 pb-0">
      <div class="col-md-6 offset-md-3">
        <RecordComponent />
      </div>
      <div class="col-md-6 offset-md-3 text-center">
        <div class="d-grid">
          &nbsp;&nbsp;
          <button
            type="button"
            class="btn btn-primary btn-lg"
            @click="toReceiver()"
          >
            Vraag plaat aan
          </button>
          <button
            type="button"
            class="btn btn-outline-secondary btn-lg mt-3"
            @click="resetRecord()"
          >
            Wissel plaat
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RecordComponent from "@/components/RecordComponent.vue";

export default {
  name: "TrackPreview",
  components: {
    RecordComponent,
  },
  methods: {
    resetRecord() {
      this.$router.push("/");
    },
    toReceiver() {
      this.$router.push("/receiver");
    },
  },
  beforeRouteLeave(to, from, next) {
    document.querySelectorAll("audio").forEach((el) => el.pause());
    next();
  },
};
</script>

<style lang="css" scoped></style>
