<template>
  <div class="container mt-4">
    <div class="row p-3 pb-0">
      <div class="col-md-8 offset-md-2">
        <a data-bs-toggle="modal" data-bs-target="#SongModal">
          <HomePageimage />
        </a>
      </div>
    </div>
    <ModalComponent />
  </div>
</template>

<script>
import ModalComponent from "@/components/ModalComponent.vue";
import HomePageimage from "@/components/HomePageImage.vue";

export default {
  name: "HomeView",
  created() {
    this.$store.commit("pausePreviewTrack");
  },
  components: {
    ModalComponent,
    HomePageimage,
  },
  beforeRouteLeave(to, from, next) {
    const backdrop = document.querySelector(".modal-backdrop");
    if (backdrop) {
      backdrop.parentNode.removeChild(backdrop);
    }
    next();
  },
};
</script>

<style lang="css" scoped>
#Heart {
  display: none !important;
}
</style>
