<template>
  <div class="container">
    <LogoComponent />
    <div class="col-md-6 offset-md-3 text-center mb-4 mt-3">
      <h1>Bedankt, {{ getSenderName }}!</h1>
    </div>
    <div class="col-md-6 offset-md-3 text-center mb-2">
      <p class="lead">
        Wij gaan aan de slag met jouw plaat. Deze zal voor eind februari bij
        {{ getReceiverName }} op de mat liggen. Heb je de vibe te pakken? Druk
        op repeat om nog een plaat aan te vragen. Je hebt nog tot 25 februari om
        plaatjes aan te vragen.
      </p>
    </div>
    <div class="col-md-6 offset-md-3 text-center mb-2">
      <div class="d-grid">
        <button
          type="button"
          class="btn btn-primary btn-lg"
          @click="resetApp()"
        >
          Repeat
          <i class="bi bi-repeat"></i>
        </button>

        <button
          type="button"
          class="btn btn-secondary btn-lg mt-3"
          @click="exitApp()"
        >
          Bekijk creatieve ideeën.
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import LogoComponent from "@/components/LogoComponent.vue";

export default {
  name: "ThankYou",
  components: {
    LogoComponent,
  },
  computed: {
    getSenderName() {
      return this.$store.getters.getSenderName;
    },
    getReceiverName() {
      return this.$store.getters.getReceiverName;
    },
  },
  methods: {
    resetApp() {
      this.$store.commit("resetReceiverValues");
      this.$router.push("/");
    },
    exitApp() {
      let url =
        "https://www.quantes.nl/cases/?utm_source=Sendyourheartbeat.com&utm_medium=Landingspage&utm_campaign=Sendyourheartbeat.com";
      window.open(url, "_blank").focus();
    },
  },
};
</script>
