import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";
import axios from "axios";
import router from "../router";

const vuexLocalStorage = new VuexPersistence({
  key: "vuex", // The key to store the state on in the storage provider.
  storage: window.localStorage, // or window.sessionStorage or localForage
  // Function that passes the state and returns the state with only the objects you want to store.
  // reducer: (state) => state,
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  // filter: mutation => (true)
});

export default createStore({
  plugins: [vuexLocalStorage.plugin],
  state: {
    loading: false,
    track: null,
    receiverName: null,
    receiverStreet: null,
    receiverPostcode: null,
    receiverCity: null,
    receiverCountry: null,
    message: null,
    senderName: null,
    senderEmailaddress: null,
    previewTrack: null,
  },
  getters: {
    getLoading(state) {
      return state.loading;
    },
    getTrack(state) {
      return state.track;
    },
    getReceiverName(state) {
      return state.receiverName;
    },
    getReceiverStreet(state) {
      return state.receiverStreet;
    },
    getReceiverPostcode(state) {
      return state.receiverPostcode;
    },
    getReceiverCity(state) {
      return state.receiverCity;
    },
    getReceiverCountry(state) {
      return state.receiverCountry;
    },
    getMessage(state) {
      return state.message;
    },
    getSenderName(state) {
      return state.senderName;
    },
    getSenderEmailaddress(state) {
      return state.senderEmailaddress;
    },
  },
  mutations: {
    setLoading(state, value) {
      state.loading = value;
    },
    setTrack(state, value) {
      state.track = value;
    },
    setReceiverName(state, value) {
      state.receiverName = value;
    },
    setReceiverStreet(state, value) {
      state.receiverStreet = value;
    },
    setReceiverPostcode(state, value) {
      state.receiverPostcode = value;
    },
    setReceiverCity(state, value) {
      state.receiverCity = value;
    },
    setReceiverCountry(state, value) {
      state.receiverCountry = value;
    },
    setMessage(state, value) {
      state.message = value;
    },
    setSenderName(state, value) {
      state.senderName = value;
    },
    setSenderEmailaddress(state, value) {
      state.senderEmailaddress = value;
    },
    resetReceiverValues(state) {
      state.track = null;
      state.receiverName = null;
      state.receiverStreet = null;
      state.receiverPostcode = null;
      state.receiverCity = null;
      state.receiverCountry = null;
      state.message = null;
    },
    setPreviewTrack(state, value) {
      state.previewTrack = new Audio(value);
    },
    playPreviewTrack(state) {
      if (typeof state.previewTrack.play === "function") {
        state.previewTrack.play();
      }
    },
    pausePreviewTrack(state) {
      if (typeof state.previewTrack.pause === "function") {
        state.previewTrack.pause();
      }
    },
  },
  actions: {
    saveEntry(state) {
      state.commit("setLoading", true);
      let token = "API-c06274de73bb7597b1dbdde25e24667f2e917e34";
      let apiurl = "https://db.sendyourheartbeat.com/api/content/item/entries";

      let data = {};
      data.to_name = this.getters.getReceiverName;
      data.to_street = this.getters.getReceiverStreet;
      data.to_postcode = this.getters.getReceiverPostcode;
      data.to_city = this.getters.getReceiverCity;
      data.to_country = this.getters.getReceiverCountry;
      data.message = this.getters.getMessage;
      data.from_name = this.getters.getSenderName;
      data.from_email = this.getters.getSenderEmailaddress;
      data.track_id = this.getters.getTrack.id;
      data.track_uri = this.getters.getTrack.uri;
      data.track_name = this.getters.getTrack.name;
      data.track_artist = this.getters.getTrack.artists[0].name;
      data.track_duration = this.getters.getTrack.duration_ms;
      data.track_image = this.getters.getTrack.album.images[0].url;

      axios
        .post(
          apiurl,
          { data },
          {
            headers: {
              "api-key": token,
            },
          }
        )
        .then(() => {
          // console.log(response.data);
          state.commit("setLoading", false);
          router.push("thankyou");
        })
        .catch((err) => console.log(err));
    },
  },
  modules: {},
});
