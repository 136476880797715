<template lang="html">
  <div class="container bg-white">
    <!-- <nav class="navbar navbar-expand-lg">
      <div class="container p-0">
        <a class="navbar-brand" href="/">
          <img
            src="../assets/logo.svg"
            alt="Bootstrap"
            width="141"
            height="70"
          />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="/">Home</a>
            </li>
          </ul>
        </div>
      </div>
    </nav> -->
  </div>
</template>

<script>
export default {
  name: "NavBar",
};
</script>

<style lang="css" scoped></style>
